<template>
  <div id="forget">
    <div class="nav">
		<img src="../../public/img/icons/logoNew.png" alt="">
	</div>
	<div class="content">
		<h3>忘记密码</h3>
		<!-- <p class="content-des">输入您用来接收信息的邮箱！</p> -->
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
			<el-form-item prop="user">
				<el-input class="content-input" size="mini" placeholder="请输入您的用户名" clearable v-model="ruleForm.user"></el-input>
			</el-form-item>
			<el-form-item prop="Email">
				<el-input class="content-input" size="mini" placeholder="请输入您用来接收信息的邮箱" clearable v-model="ruleForm.Email"></el-input>
			</el-form-item>
		</el-form>
		<el-button class="content-btn" size="mini" type="primary" @click="submitForm('ruleForm')">提交</el-button>
		<div class="content-footer">
			<ul>
				<li>帮助</li>
				<li>隐私</li>
				<li>条款</li>
			</ul>
			<span>Copyright @ 2019神州数码医疗科技股份有限公司</span>
		</div>
	</div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { Loading } from 'element-ui';
let loadingInstance  = null;
export default {
	name: 'ForgetPsw',
	data(){
		return {
			ruleForm: {
				user: '',
				Email: ''
			},
			rules: {
				user: [
					{ required: true, message: '请输入您的用户名', trigger: 'blur' },
				],
				Email: [
					{ required: true, message: '请输入您用来接收信息的邮箱', trigger: 'blur' },
					{ pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请输入正确的邮箱格式' }
				],
			}
		}
	},
	methods: {
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
			if (valid) {
				 loadingInstance = Loading.service({ fullscreen: true, text: '正在发送邮件，请稍等。',});
				const postData = {
					// "userItCode":"DCHtest",
  					// "userEmail":"18801117648@163.com",
					"userItCode": this.ruleForm.user,
					"userEmail": this.ruleForm.Email,
				};
				this.$ajax.post("/api/base/resetPassword",postData).then(res => {
					loadingInstance.close();
					if(res.code == 200) {
						window.localStorage.clear();
						window.location.href = res.data;
					}else{
						this.$message({
							type: 'error',
							showClose: true,
							message: res.msg,
							center: true
						});
					}
				}).catch(error => {
					loadingInstance.close();
					console.log(error);
				});
			} else {
				return false;
			}
			});
		},
	},
	beforeRouteLeave(to,from,next){
             loadingInstance && loadingInstance.close();
             next()
        }
}
</script>

<style scoped lang="scss">
	#forget{
		width: 100%;
		height: 100%;
		background:rgba(73,150,255,0.14);
		.nav{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 48px;
			background: #0D83D8;
			line-height: 48px;
			z-index: 10;
			img{
				vertical-align: middle;
				padding-left: 16px;
			}
		}
		.content{
			position: absolute;
			top: 50%;
			left: 50%;
			height: 74%;
			width: 300px;
			-webkit-transform: translateX(-50%) translateY(-50%);
			.el-form-item {
				// margin-bottom: 10px;
			}
			h3{
				font-size: 20px;
				text-align: center;
				margin-bottom: 50px;
			}
			.content-des{
				padding-left: 8px;
				font-size: 12px;
				color:#787878;
				margin-bottom: 6px;
			}
			.content-btn{
				width: 100%;
				margin-top: 10px;
			}
			.content-footer{
				position: absolute;
				bottom: 0;
				text-align: center;
				width: 100%;
				li,span{
					display: inline-block;
					font-size: 12px;
					color: #787878;
					margin: 0 15px;
				}
				span{
					margin-top: 10px;
					font-size: 10px;
				}
			}
		}
	}
</style>
<style lang="scss">
	#forget .el-form-item__content{
		line-height: 30px;
	}
</style>